











import { Component, Vue, Prop } from 'vue-property-decorator'
import Marketcap from '@/components/heatmap-types/Marketcap.vue'

@Component({
  components: {
    Marketcap
  }
})
export default class App extends Vue {
  @Prop({ default: '300px' }) readonly height!: string
  @Prop({ default: '100%' }) readonly width!: string
  @Prop({ default: 20 }) readonly numOfCoins!: number
  @Prop({ default: 'USD' }) readonly currencyCode!: string
}
