








/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue, Prop } from 'vue-property-decorator'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import {
  heatmapConfigInterface,
  heatmapTileInfoInterface,
  heatmapDataInterface
} from '@/types/heatmap'

import { CurrencyOptions, CurrencyInterface } from '@/types/currency'

am4core.useTheme(am4themes_animated)
am4core.options.autoDispose = true

@Component
export default class Marketcap extends Vue {
  @Prop({ default: '300px' }) readonly height!: string
  @Prop({ default: '100%' }) readonly width!: string
  @Prop({ default: 20 }) readonly numOfCoins!: number
  @Prop({ default: 'USD' }) readonly currencyCode!: CurrencyOptions

  private data: heatmapDataInterface[] | any

  private chart: any = null

  private currency: CurrencyInterface = {
    currency_code: 'USD',
    locale: 'en',
    conv_rate: 1
  }

  private level1_column: any | undefined
  private level1_bullet: any | undefined
  private level1: any | undefined

  heatmapConfigs: heatmapConfigInterface = {
    display: 'marketcap',
    time_frame: 'daily',
    num_of_coins: this.numOfCoins,
    exchange: 'all',
    currency_code: this.currencyCode
  }

  heatmapTileInfo: heatmapTileInfoInterface = {
    tooltipText: `[bold]{symbol_name}[/]
                  ---------------------
                  7 DAY: {price1week}%
                  30 DAY: {price30day}%
                  1 YEAR: {price1year}%
                  1 YEAR TO DATE: {price_year_to_date}%
                  TREND MEAN: {qma_score}
                  RSI 2H: {rsi2h}
                  MC: {marketcap} Million
                  ---------------------
                  [font-size: 13px; font-weight:600;]Powered by Quantify Crypto[/]`,
    tileText: `[font-size: {fontSize}px font-weight: 400;]{qc_key}[/]
              [font-size: {fontSizeLev2}px; font-weight: 400;] {price_usd}
              {price24h} % [/]`
  }

  $refs!: {
    chartdiv: HTMLElement
  }

  async mounted() {
    window.addEventListener('resize', this.onResize)

    if (this.chart) {
      this.chart.dispose()
    }

    this.renderChart()
  }

  beforeDestroy() {
    if (this.chart) {
      window.removeEventListener('resize', this.onResize)
      this.chart.dispose()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: any) {
    this.chart.svgContainer.measure()
  }

  async renderChart() {
    this.$gtag.event(`Open Widget | ${window.location.href}`, {
      event_category: `Heatmap Widget`,
      event_label: 'render',
      value: 1
    })

    am4core.addLicense('CH187387301')
    am4core.useTheme(am4themes_animated)
    this.chart = am4core.create(this.$refs.chartdiv, am4charts.TreeMap)
    this.chart.preloader.disabled = true
    this.chart.padding(0, 0, 0, 0)
    this.chart.hiddenState.properties.opacity = 0

    try {
      this.data = await this.heatmapData()
    } catch (error) {
      this.data = []
    }

    this.chart.data = this.formatData(this.data, this.heatmapConfigs.time_frame)

    this.chart.colors.step = 2

    /* Define data fields */
    this.chart.dataFields.value = 'marketcap_index'
    this.chart.dataFields.name = 'symbol_name'
    this.chart.dataFields.color = 'color'
    this.chart.dataFields.children = 'children'

    /* Configure top-level series */
    this.level1 = this.chart.seriesTemplates.create('0')
    // eslint-disable-next-line camelcase,@typescript-eslint/camelcase
    this.level1_column = this.level1.columns.template
    this.level1_column.fillOpacity = 1
    this.level1_column.stroke = am4core.color('#252525')
    this.level1_column.strokeWidth = 1
    this.level1_column.strokeOpacity = 1
    this.level1_column.tooltipText = this.heatmapTileInfo.tooltipText

    /* Add bullet labels */
    // eslint-disable-next-line camelcase,@typescript-eslint/camelcase
    this.level1_bullet = this.level1.bullets.push(new am4charts.LabelBullet())
    this.level1_bullet.locationY = 0.5
    this.level1_bullet.locationX = 0.5

    this.level1_bullet.label.adapter.add('text', function(
      url: any,
      target: any
    ) {
      const key = target.dataItem.dataContext.dataContext.qc_key
      target.url = `https://quantifycrypto.com/coins/${key}`
      target.urlTarget = '_blank'
    })

    const tileBody = this.heatmapTileInfo.tileText
    this.level1_bullet.label.adapter.add('text', function(
      text: any,
      target: any
    ) {
      let fontSize: any =
        (target.availableWidth /
          (target.dataItem.dataContext.dataContext.qc_key.length * 0.83)) *
        0.75
      let fontSizeLev2: any =
        (target.availableWidth /
          (target.dataItem.dataContext.dataContext.price_usd.toString().length *
            0.83)) *
        0.5

      if (target.availableHeight < fontSize * 2) {
        fontSize = target.availableHeight / 2.5
        fontSizeLev2 = fontSize / 2.5
      }

      if (fontSizeLev2 > 20) {
        fontSizeLev2 = 20
      }

      return tileBody
        .replace('{fontSize}', fontSize)
        .replace('{fontSizeLev2}', fontSizeLev2)
    })
    this.level1_bullet.label.fill = am4core.color('#fff')
  }

  private async heatmapData() {
    try {
      const { data } = await this.axios.get(`/api/v1.0/heatmaps/config`, {
        params: {
          display: this.heatmapConfigs.display,
          exchange: this.heatmapConfigs.exchange,
          num_of_coins: this.heatmapConfigs.num_of_coins,
          currency: this.heatmapConfigs.currency_code
        }
      })

      this.currency = data.currency

      return data.data
    } catch (error) {
      throw new Error(error)
    }
  }

  private formatData(
    data: Array<Record<string, any>>,
    timeFrame: string
  ): Array<Record<string, any>> {
    function setTimeFrame(timeFrame: string): string {
      const frames: { [key: string]: string } = {
        weekly: 'price1week',
        daily: 'price24h',
        monthly: 'price30day',
        yearly: 'price1year',
        ytd: 'price_year_to_date'
      }
      return frames[timeFrame]
    }

    function setColor(x: number) {
      if (x * 100 > 0 && x * 100 <= 1) {
        return '#71c175'
      } else if (x * 100 > 1 && x * 100 <= 2.5) {
        return '#4eb153'
      } else if (x * 100 > 2.5 && x * 100 <= 5) {
        return '#3e8e42'
      } else if (x * 100 > 5) {
        return '#2f6a32'
      } else if (x * 100 <= 0 && x * 100 >= -1) {
        return '#ff8080'
      } else if (x * 100 < -1 && x * 100 >= -2.5) {
        return '#ff4d4d'
      } else if (x * 100 < -2.5 && x * 100 >= -5) {
        return '#ff1a1a'
      } else if (x * 100 < -5) {
        return '#e60000'
      }
    }

    // function price24hAbsFormatter(params: number | null) {
    //   if (params === -1) {
    //     return '-'
    //   } else if (params == null) {
    //     return '-'
    //   } else {
    //     return Math.abs(params * 100).toFixed(2)
    //   }
    // }

    function price24hFormatter(params: number | null): number {
      if (params == null) {
        return 0
      } else {
        return parseFloat((params * 100).toFixed(2))
      }
    }

    for (let i = 0; i < data.length; i++) {
      data[i].color = setColor(data[i][setTimeFrame(timeFrame)])
      data[i].price24h = price24hFormatter(data[i].price24h)
      data[i].price24hAbs = price24hFormatter(data[i].price24h)

      if (data[i].price_usd.toFixed(4) > 1000) {
        data[i].price_usd = new Intl.NumberFormat(this.currency.locale, {
          style: 'currency',
          currency: this.currency.currency_code,
          minimumFractionDigits: 2
        }).format(parseFloat(data[i].price_usd.toFixed(4)))
      } else {
        data[i].price_usd = new Intl.NumberFormat(this.currency.locale, {
          style: 'currency',
          currency: this.currency.currency_code,
          minimumFractionDigits: 4
        }).format(parseFloat(data[i].price_usd.toFixed(4)))
      }

      data[i].price1week = price24hFormatter(data[i].price1week)
      data[i].price30day = price24hFormatter(data[i].price30day)
      data[i].price1year = price24hFormatter(data[i].price1year)
      data[i].price_year_to_date = price24hFormatter(data[i].price_year_to_date)
      data[i].marketcap = data[i].marketcap = new Intl.NumberFormat(
        this.currency.locale,
        {
          style: 'currency',
          currency: this.currency.currency_code,
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }
      ).format(Math.round(data[i].marketcap / 10 ** 6))
    }
    return data
  }
}
