import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
Vue.use(VueAxios, axios)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID }
})
Vue.use(Vuex)
Vue.use(VueRouter)

Vue.use(vueCustomElement)
Vue.customElement('qc-heatmap', new App().$options)
